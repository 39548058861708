// RouterComponent.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../Components/Home/Home';
import UMAHospital from '../Components/QR Registration/UMA Hospital/UMAHospital';
import MasonicHospital from '../Components/QR Registration/Masonic Hospital/MasonicHospital';
import PageNotFound from '../Components/UpcomingPages/404error';
import RajeshClinic from '../Components/QR Registration/Rajesh Clinic/RajeshClinic';
import QmsTokenScreen from '../Components/QMS/QmsTokenScreen';
import QMSLogin from '../Components/QMS/QMSLogin';
import QMSLinkList from '../Components/QMS/QMSLinkList';
import AppointmentChange from '../Components/QMS/AppointmentChange';
import ShivaniMedicalCenter from '../Components/QR Registration/ShivaniMedicalCenter/ShivaniMedicalCenter';
import { CustomRegistrationForm } from '../Components/QR Registration/CustomRegistration/CustomRegistrationForm';
import { PrathipaHospital } from '../Components/QR Registration/Prathipa Hospital/PrathipaHospital';

const RouterComponent = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/Registration/RajeshClinic" element={<RajeshClinic />} />
        <Route path="/Registration/UMA_Pharmacy" element={<UMAHospital />} />
        <Route path="/Registration/Masonic_Hospital" element={<MasonicHospital />} />
        <Route path="/Registration/Shivani_medical_center" element={<ShivaniMedicalCenter />} />
        <Route path="/TokenScreen" element={<QMSLogin />} />
        <Route path="/TokenScreen/LinkList" element={<QMSLinkList />} />
        <Route path="/TokenScreen/LinkList/QMSTokenScreen" element={<QmsTokenScreen />} />
        <Route path="/AppointmentChange" element={<AppointmentChange />} />
        <Route path='/Registration' element={<CustomRegistrationForm />} />
        <Route path='/Registration/Prathipa_Hospital' element={<PrathipaHospital />} />
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default RouterComponent;
